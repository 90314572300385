import {
  FooterWrapper,
  ContactDetailsWrapper,
  SocialWrapper,
  LegalText,
  LinksWrapper,
  FooterMenuWrapper,
  NewsletterWrapper,
  ContactWrapper,
  SubHeading,
  LogoWrapper,
  FooterBottom,
  SocialLinkWrapper,
  InnerContactWrapper,
} from "./Footer.styled";
import { FC, useContext } from "react";
import { useDevice } from "common/hooks/useDevice";
import SocialLink from "Components/SocialLink";
import { ContactDetailsProps, FooterProps } from "../types/Layout.types";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { PublicationContext } from "common/context/Publication";
import Logo from "Components/Logo";
import EmailIcon from "common/icons/email";
import PhoneIcon from "common/icons/phone";
import NewsLetterSignUp from "Components/NewsLetterSignUp";
import FooterMobile from "./FooterMobile";



type Props = {
  footer: { menu: FooterProps[] };
  contactDetails: ContactDetailsProps;
};

const Footer: FC<Props> = ({ footer, contactDetails }) => {
  const { publication, config } =
    useContext<publicationTypes>(PublicationContext);

  const { screenSize } = useDevice();
  if (!footer) {
    return null;
  }
  const footerMenu = footer?.menu ? footer.menu : []

  

  const renderContactText = (text: string) => {
    if (text) {
      return (
        <>
          {text}
          <br />
        </>
      );
    }
  };

  const followText =
    contactDetails?.facebook || contactDetails?.twitter ? "Follow us" : "";

  return (
  <> 
    {!screenSize.medium ? (
    <>
      <FooterWrapper>
        <ContactDetailsWrapper>
          <LogoWrapper>
            <Logo/>
          </LogoWrapper> 
        {contactDetails && (
          <ContactWrapper>
            <InnerContactWrapper>
              <div>
                <SubHeading>Contact</SubHeading>
                <address>
                  {renderContactText(contactDetails?.buildingName)}
                  {renderContactText(contactDetails?.streetAddress)}
                  {renderContactText(contactDetails?.town)}
                  {renderContactText(contactDetails?.county)}
                  {renderContactText(contactDetails?.country)}
                  {renderContactText(contactDetails?.postcode)}
                  <br/>
                  {contactDetails?.phone && (
                    <LinksWrapper>
                    <PhoneIcon/>
                      Tel:{" "}
                      <a
                        href={`tel:${contactDetails?.phone.replace(/\s/g, "")}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {contactDetails?.phone}
                      </a>
                      <br />
                    </LinksWrapper>
                  )}
                  {contactDetails?.email && (
                    <LinksWrapper>
                      <EmailIcon />
                      <a
                        href={`mailto:${contactDetails?.email}`}
                        target="_blank"
                        rel="noreferrer"
                        className="contact-link"
                      >
                      {contactDetails?.email}
                      </a>
                    </LinksWrapper>
                  )}
                </address>
              </div>
            </InnerContactWrapper>

            <SocialWrapper>
              {followText && (
                  <SubHeading>{followText}</SubHeading>
              )}
              <SocialLinkWrapper>
                {contactDetails?.facebook && (
                  <SocialLink
                    dark={true}
                    type="facebook"
                    url={contactDetails?.facebook}
                  />
                )}
                {contactDetails?.twitter && (
                  <SocialLink
                    dark={true}
                    type="twitter"
                    url={contactDetails?.twitter}
                  />
                )}
              </SocialLinkWrapper>
            </SocialWrapper>
          </ContactWrapper>
        )}
      </ContactDetailsWrapper>
      <FooterMenuWrapper id="footer-menu">
        <SubHeading>Further Links</SubHeading>
        <ul className="footer-menu">
          {footerMenu && footerMenu.length > 0 && (
            footerMenu.map((item, i) => {
              return (
              <li key={i}>
                  <a key={i} href={item.path} id={item.id}>
                    {item.title}
                  </a>
              </li>
              )
            })
          )}
        </ul>
      </FooterMenuWrapper>
      <NewsletterWrapper>
        <NewsLetterSignUp className="newsletter-footer"/>
      </NewsletterWrapper>
      {/* <!-- InMobi Choice. Consent Manager Tag v3.0 (for TCF 2.2) --> */}
      <script
        type="text/javascript"
        async
        dangerouslySetInnerHTML={{
          __html: `
          try {
            (function() {
              var host = window.location.hostname;
              var element = document.createElement('script');
              var firstScript = document.getElementsByTagName('script')[0];
              var url = 'https://cmp.inmobi.com'
                .concat('/choice/', 'uGUachD16z0h6', '/', host, '/choice.js?tag_version=V3');
              var uspTries = 0;
              var uspTriesLimit = 3;
              element.async = true;
              element.type = 'text/javascript';
              element.src = url;
            
              firstScript.parentNode.insertBefore(element, firstScript);
            
              function makeStub() {
                var TCF_LOCATOR_NAME = '__tcfapiLocator';
                var queue = [];
                var win = window;
                var cmpFrame;
            
                function addFrame() {
                  var doc = win.document;
                  var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);
            
                  if (!otherCMP) {
                    if (doc.body) {
                      var iframe = doc.createElement('iframe');
            
                      iframe.style.cssText = 'display:none';
                      iframe.name = TCF_LOCATOR_NAME;
                      doc.body.appendChild(iframe);
                    } else {
                      setTimeout(addFrame, 5);
                    }
                  }
                  return !otherCMP;
                }
            
                function tcfAPIHandler() {
                  var gdprApplies;
                  var args = arguments;
            
                  if (!args.length) {
                    return queue;
                  } else if (args[0] === 'setGdprApplies') {
                    if (
                      args.length > 3 &&
                      args[2] === 2 &&
                      typeof args[3] === 'boolean'
                    ) {
                      gdprApplies = args[3];
                      if (typeof args[2] === 'function') {
                        args[2]('set', true);
                      }
                    }
                  } else if (args[0] === 'ping') {
                    var retr = {
                      gdprApplies: gdprApplies,
                      cmpLoaded: false,
                      cmpStatus: 'stub'
                    };
            
                    if (typeof args[2] === 'function') {
                      args[2](retr);
                    }
                  } else {
                    if(args[0] === 'init' && typeof args[3] === 'object') {
                      args[3] = Object.assign(args[3], { tag_version: 'V3' });
                    }
                    queue.push(args);
                  }
                }
            
                function postMessageEventHandler(event) {
                  var msgIsString = typeof event.data === 'string';
                  var json = {};
            
                  try {
                    if (msgIsString) {
                      json = JSON.parse(event.data);
                    } else {
                      json = event.data;
                    }
                  } catch (ignore) {}
            
                  var payload = json.__tcfapiCall;
            
                  if (payload) {
                    window.__tcfapi(
                      payload.command,
                      payload.version,
                      function(retValue, success) {
                        var returnMsg = {
                          __tcfapiReturn: {
                            returnValue: retValue,
                            success: success,
                            callId: payload.callId
                          }
                        };
                        if (msgIsString) {
                          returnMsg = JSON.stringify(returnMsg);
                        }
                        if (event && event.source && event.source.postMessage) {
                          event.source.postMessage(returnMsg, '*');
                        }
                      },
                      payload.parameter
                    );
                  }
                }
            
                while (win) {
                  try {
                    if (win.frames[TCF_LOCATOR_NAME]) {
                      cmpFrame = win;
                      break;
                    }
                  } catch (ignore) {}
            
                  if (win === window.top) {
                    break;
                  }
                  win = win.parent;
                }
                if (!cmpFrame) {
                  addFrame();
                  win.__tcfapi = tcfAPIHandler;
                  win.addEventListener('message', postMessageEventHandler, false);
                }
              };
            
              makeStub();
            
              var uspStubFunction = function() {
                var arg = arguments;
                if (typeof window.__uspapi !== uspStubFunction) {
                  setTimeout(function() {
                    if (typeof window.__uspapi !== 'undefined') {
                      window.__uspapi.apply(window.__uspapi, arg);
                    }
                  }, 500);
                }
              };
            
              var checkIfUspIsReady = function() {
                uspTries++;
                if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
                  console.warn('USP is not accessible');
                } else {
                  clearInterval(uspInterval);
                }
              };
            
              if (typeof window.__uspapi === 'undefined') {
                window.__uspapi = uspStubFunction;
                var uspInterval = setInterval(checkIfUspIsReady, 6000);
              }
            })()
        } catch (e) {
          console.error("Error in InMobi Choice consent script:", e.message);
        }
          `,
        }}
      />

      <script
        dangerouslySetInnerHTML={{
          __html: `
              var _comscore = _comscore || [];
              _comscore.push({ c1: "2", c2: "22002390",  options: {
                 enableFirstPartyCookie: true
                 } });
              (function() {
                var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
                s.src = "https://sb.scorecardresearch.com/cs/22002390/beacon.js";
                el.parentNode.insertBefore(s, el);
              })();
`,
        }}
      />
      <noscript>
        <img src="https://sb.scorecardresearch.com/p?c1=2&amp;c2=22002390&amp;cv=4.4.0&amp;cj=1" />
      </noscript>
    </FooterWrapper>
    <FooterBottom>
    <LegalText>
      Owned or licensed to Tindle Newspapers Ltd. 
      | Independent Family-Owned Newspapers | Copyright & Trade Mark
      Notice & 2013 - {new Date().getFullYear()}
    </LegalText>
  </FooterBottom>
    </>
  ) : (
  <FooterMobile footer={footer} contactDetails={contactDetails}/>
   )}
   </>
  );
};


export default Footer;
