import { FC, useEffect, useRef } from "react";
import { Twirl as Hamburger } from "hamburger-react";
import {
  FullMenuWrapper,
  StyledHamburgerWrapper,
} from "./FullMenu.styled";
import { FullMenuProps } from "common/Layout/types/Layout.types";

interface Props {
  setFullMenuOpen: (x: boolean) => void;
  openFullMenu: boolean;
  fullMenu: FullMenuProps;
}

const FullMenu: FC<Props> = ({ fullMenu, openFullMenu, setFullMenuOpen }) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setFullMenuOpen(false);
      }
    };

    if (openFullMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openFullMenu, setFullMenuOpen]);

  return (
    <FullMenuWrapper ref={menuRef}>
      <StyledHamburgerWrapper>
        <Hamburger
          size={20}
          hideOutline={true}
          toggled={openFullMenu}
          toggle={() => setFullMenuOpen(!openFullMenu)}
        />
      </StyledHamburgerWrapper>
    </FullMenuWrapper>
  );
};

export default FullMenu;
